import { Platform } from '../platform.model';
import { CreditDto } from './credit.dto';
import { AvailableCredits, AvailableProduct } from './credit.model';

import { ProductCode } from '../../../../util-product/src/lib/models/product.model';
import { ProductPlatformGroup } from '@mkp/shared/util-product';

export const availableCredits = (credits: CreditDto[]): AvailableCredits[] => {
  const summary = [] as AvailableCredits[];

  for (const key in Platform) {
    const platform = key.toUpperCase() as Platform;

    const aggregate = credits
      .filter((credit) => credit.product.type.startsWith(platform))
      .map(({ product, _availableCredits }) => ({ type: product.type, _availableCredits }))
      .reduce((acc, cur) => aggregation(acc, cur), [] as AvailableProduct[]);

    aggregate.length > 0 ? summary.push({ platform, aggregate }) : null;
  }

  return summary;
};

export const aggregation = (acc: AvailableProduct[], current: AvailableProduct) => {
  const found = acc.find((product) => product.type === current.type);
  found ? (found._availableCredits += current._availableCredits) : acc.push(current);
  return acc;
};

export const isRefundable = (updatableProperties: string[] | undefined): boolean =>
  Array.isArray(updatableProperties) && updatableProperties.includes('state');

export const isFreeProductCode = (productType: unknown): boolean =>
  [ProductCode.JOBSOFFER22FREE, ProductCode.JS24FREE, ProductCode.JOBUP24FREE].includes(
    productType as ProductCode
  );

export const isJs24ProductCode = (productType: ProductCode): boolean =>
  [
    ProductCode.JS24FREE,
    ProductCode.JS24SOLO,
    ProductCode.JS24PLUS,
    ProductCode.JS24BASIC,
    ProductCode.JS24ADVANCED,
  ].includes(productType);

export const isJobUpProductCode = (productType: ProductCode): boolean =>
  [
    ProductCode.JOBUP24FREE,
    ProductCode.JOBUP24LITE,
    ProductCode.JOBUP24BASIC,
    ProductCode.JOBUP24ADVANCED,
    ProductCode.JOBUP24ULTIMATE,
    ProductCode.JOBUP24ULTIMATEPLUS,
  ].includes(productType);

export const getProductPlatformGroup = (type: ProductCode): ProductPlatformGroup => {
  if (isJs24ProductCode(type)) {
    return ProductPlatformGroup.JobScout24;
  }

  if (isJobUpProductCode(type)) {
    return ProductPlatformGroup.JobUp;
  }

  return ProductPlatformGroup.Jobs;
};
