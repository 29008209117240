import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { AppConfig } from '@config/app.config';
import {
  ActiveLegalEntityGuard,
  UnverifiedUserGuard,
  activeUserGuard,
  boUserGuardFactory,
  hasMultipleWorkspacesGuard,
  hasSelectedWorkspaceGuard,
  inactiveUserGuard,
  selectedAccountGuard,
  verifiedUserGuard,
} from '@core/guards';
import {
  applicationExistGuard,
  applicationStatusesExistGuard,
} from '@mkp/application/feature-application';
import { isNewAccountManagementGuard } from '@mkp/debug/feature-flag';
import { LogoutComponent } from '@mkp/user/feature-logout';
import { Store } from '@ngrx/store';
import { EcomAuthGuard } from '@upsell/guards/ecom-auth.guard';
import { selectIsBoUser } from '@user/store/selectors/user.selectors';
import { VacancyExistsGuard } from '@vacancy/guards';
import { visitorOnlyUrls } from './visitor-routes';
import { map } from 'rxjs';
import { selectSelectedAccount } from '@mkp/account/state';
import { concatLatestFrom } from '@ngrx/effects';

const { routes } = AppConfig;

const redirectableUrls: string[] = ['', ...Object.values(visitorOnlyUrls)];
const redirectToDashboardRoutes: Routes = redirectableUrls.map((path) => ({
  path,
  // match the full path if the path is empty string, otherwise match the prefix
  pathMatch: path ? 'prefix' : 'full',
  redirectTo: routes.dashboard,
}));

export const authenticatedRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('@mkp/layout/shell-user-layout').then((m) => m.ShellUserLayoutComponent),
    children: [
      ...redirectToDashboardRoutes,
      {
        path: routes.selectAccountMembership,
        canMatch: [isNewAccountManagementGuard, hasMultipleWorkspacesGuard],
        loadComponent: () =>
          import('@mkp/user-account-membership/feature-select-workspace').then(
            (m) => m.SelectWorkspaceComponent
          ),
      },
      {
        path: routes.dashboard,
        canMatch: [
          () => {
            const store = inject(Store);
            return store.select(selectIsBoUser).pipe(
              concatLatestFrom(() => [store.select(selectSelectedAccount)]),
              map(([isBoUser, selectedAccount]) => isBoUser && !selectedAccount)
            );
          },
        ],
        data: { title: 'META_TITLE.DASHBOARD' },
        loadChildren: () =>
          import('@mkp/user/feature-bo-dashboard').then((m) => m.BoDashboardModule),
      },
      {
        path: routes.dashboard,
        canMatch: [verifiedUserGuard, activeUserGuard, hasSelectedWorkspaceGuard],
        data: { title: 'META_TITLE.DASHBOARD' },
        loadComponent: () =>
          import('@mkp/user/feature-dashboard').then((m) => m.DashboardComponent),
      },
      {
        path: routes.error401,
        loadComponent: () => import('@mkp/core/feature-errors').then((m) => m.Error401Component),
        data: { title: 'META_TITLE.ERROR.401' },
      },
      {
        path: routes.error403,
        loadComponent: () => import('@mkp/core/feature-errors').then((m) => m.Error403Component),
        data: { title: 'META_TITLE.ERROR.403' },
      },
      {
        path: routes.error404,
        loadComponent: () => import('@mkp/core/feature-errors').then((m) => m.Error404Component),
        data: { title: 'META_TITLE.ERROR.404' },
      },
      {
        path: routes.error500,
        loadComponent: () => import('@mkp/core/feature-errors').then((m) => m.Error500Component),
        data: { title: 'META_TITLE.ERROR.500' },
      },
      {
        path: AppConfig.routes.claimManagement,
        canMatch: [boUserGuardFactory(routes.dashboard)],
        loadChildren: () =>
          import('@mkp/legal-entity/feature-claim-management').then((m) => m.LegalEntityModule),
      },
      {
        path: routes.unsubscribe,
        canMatch: [verifiedUserGuard, inactiveUserGuard],
        loadComponent: () =>
          import('@mkp/user/feature-unsubscribe').then((m) => m.UnsubscribeComponent),
        data: { title: 'META_TITLE.UNSUBSCRIBE' },
      },
      {
        path: routes.user,
        canLoad: [verifiedUserGuard, activeUserGuard],
        loadChildren: () =>
          import('@mkp/user-account-membership/feature-user-account-membership').then(
            (m) => m.featureUserAccountMembershipRoutes
          ),
      },
      {
        path: routes.company,
        canLoad: [verifiedUserGuard, activeUserGuard],
        loadChildren: () => import('@company/company.module').then((m) => m.CompanyModule),
      },
      {
        path: `${routes.vacancy}/${routes.id}`,
        redirectTo: `${routes.vacancy}/${routes.id}/${routes.applications}`,
        pathMatch: 'full',
      },
      {
        path: `${routes.vacancy}/${routes.id}/${routes.applications}`,
        canActivate: [
          ActiveLegalEntityGuard,
          VacancyExistsGuard,
          applicationStatusesExistGuard,
          applicationExistGuard,
        ],
        canMatch: [verifiedUserGuard, activeUserGuard],
        loadComponent: () => import('@mkp/application/feature-application'),
      },
      {
        path: routes.vacancy,
        canLoad: [verifiedUserGuard, activeUserGuard],
        loadChildren: () => import('@vacancy/vacancy.module').then((m) => m.VacancyModule),
      },
      {
        path: routes.upsell,
        canLoad: [verifiedUserGuard, activeUserGuard, EcomAuthGuard],
        loadChildren: () => import('@upsell/upsell.module').then((m) => m.UpsellModule),
      },
      {
        path: routes.cms,
        children: [
          {
            path: ':slug',
            loadComponent: () =>
              import('@mkp/core/feature-cms').then((m) => m.CMSLandingPageComponent),
          },
          {
            path: '**',
            redirectTo: 'jobs-lg-ecom',
            pathMatch: 'prefix',
          },
        ],
      },
      {
        path: routes.supportCenter,
        data: { title: 'META_TITLE.SUPPORT_CENTER' },
        loadComponent: () =>
          import('@mkp/core/feature-support-center').then((m) => m.SupportCenterComponent),
      },
      {
        path: routes.credits,
        data: { title: 'META_TITLE.CREDIT_OVERVIEW' },
        canLoad: [selectedAccountGuard],
        loadChildren: () =>
          import('@mkp/publication/feature-credit-overview').then((m) => m.CreditOverviewModule),
      },
      {
        path: routes.publicationStore,
        children: [
          {
            path: '',
            canLoad: [selectedAccountGuard],
            loadChildren: () =>
              import('@mkp/publication/feature-publication-store').then(
                (m) => m.FeaturePublicationStoreModule
              ),
          },
          {
            path: `${routes.orderSummary}`,
            canLoad: [selectedAccountGuard],
            loadChildren: () =>
              import('@mkp/publication/feature-order-summary').then(
                (m) => m.FeatureOrderSummaryModule
              ),
          },
          {
            path: `${routes.comparisonTable}`,
            canLoad: [selectedAccountGuard],
            loadChildren: () =>
              import('@mkp/publication/feature-publication-product-display').then(
                (m) => m.publicationFeatureComparisonTableRoutes
              ),
          },
        ],
      },
      {
        path: routes.verifyIdentity,
        canLoad: [UnverifiedUserGuard],
        loadChildren: () =>
          import('@mkp/onboarding/feature-verify-identity').then((m) => m.VerifyIdentityModule),
      },
      {
        path: routes.claimCompany,
        canLoad: [verifiedUserGuard, inactiveUserGuard],
        canActivate: [verifiedUserGuard, inactiveUserGuard],
        data: { title: 'META_TITLE.CLAIMCOMPANY' },
        loadComponent: () =>
          import('@mkp/onboarding/feature-claim-company').then((m) => m.ClaimCompanyComponent),
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
      { path: '**', redirectTo: '/' + routes.error404 },
    ],
  },
];
