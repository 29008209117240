import { BaseHttpResource, CoreListEnvelop } from '@mkp/shared/data-access';
import { LegalEntityDto } from './legal-entity.dto';
import { LegalEntity } from './legal-entity.model';
import { map, Observable } from 'rxjs';
import { mapLegalEntityDtoToLegalEntity } from './legal-entity.mapper';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LegalEntityResource extends BaseHttpResource<LegalEntityDto, CoreListEnvelop<LegalEntityDto>, LegalEntity> {
  constructor() {
    super('legal-entity');
  }

  patchState({ id, state, _version }: { id: LegalEntity['id'], state: LegalEntity['state'], _version: LegalEntity['_version'] }): Observable<LegalEntity> {
    return this.update(id, { state, _version }, 'patch').pipe(
      map((legalEntityDto) => mapLegalEntityDtoToLegalEntity(legalEntityDto))
    );
  }
}