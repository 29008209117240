import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

const defaultTitle = { title: 'BACK', param: {} } as const;

export interface GoBackText {
  title: string;
  param?: Record<string, string>;
}

@Injectable({
  providedIn: 'root',
})
export class GoBackService {
  private readonly route = new ReplaySubject<string | null>(1);
  private readonly text = new BehaviorSubject<GoBackText>(defaultTitle);
  private readonly clicked = new Subject<void>();

  readonly route$: Observable<string | null> = this.route.asObservable();
  readonly text$: Observable<GoBackText> = this.text.asObservable();
  readonly clicked$: Observable<void> = this.clicked.asObservable();

  public setRoute(route: string) {
    this.route.next(`/${route}`);
  }

  public clearRoute() {
    this.route.next(null);
  }

  public setTitle(title: string, param = {}) {
    this.text.next({ title, param });
  }

  public clearText() {
    this.text.next(defaultTitle);
  }

  public click() {
    this.clicked.next();
  }

  public clear(): void {
    this.clearRoute();
    this.clearText();
  }
}
