import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SplashscreenService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  toggle() {
    this.document.body.classList.toggle('splashscreen');
  }

  show() {
    this.document.body.classList.add('splashscreen');
  }

  hide() {
    this.document.body.classList.remove('splashscreen');
  }
}
