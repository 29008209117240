import { Asset } from './asset.model';

/**
 * Use this to append style to the asset itself
 */
export const ASSET_STYLES: Asset[] = [
  {
    name: 'jobcloud_logo',
    styles: {
      width: 'auto',
      cursor: 'pointer',
      height: '22px',
    },
  },
  {
    name: 'jobs',
    styles: {
      width: '5.5rem',
      height: '100%',
    },
  },
  {
    name: 'jobscout24',
    styles: {
      width: '3rem',
    },
  },
  {
    name: 'jobup',
    styles: {
      width: '5.5rem',
      height: '100%',
    },
  },
  {
    name: 'info',
    styles: {
      width: '24px',
    },
  },
  {
    name: 'open-in-view',
    styles: {
      width: '12px',
    },
  },
  {
    name: 'bullet',
    styles: {
      width: '8px',
      height: '8px',
    },
  },
];
