import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stripHtml', standalone: true })
export class StripHtmlPipe implements PipeTransform {
  htmlRegex = new RegExp(/<\/?[^>]+(>|$)/g);

  transform(text: string): string {
    return text?.replace(this.htmlRegex, '') ?? '';
  }
}
