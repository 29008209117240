import { Params } from '@angular/router';
import {
  ANY_ORDER_COMPLETE,
  BOOST_VACANCY,
  CLAIM_MANAGEMENT,
  CREATE_VACANCY,
  DASHBOARD,
  EDIT_VACANCY,
  ME,
  MY_USER_PROFILE,
  PREVIEW_VACANCY_WITH_ID,
  PUBLICATION_STORE,
  PUBLISH_VACANCY,
  SELECT_WORKSPACE,
  STORE_COMPARISON_TABLE,
  STORE_ORDER_COMPLETE,
  STORE_SUMMARY,
  SUMMARY_ORDER_COMPLETE,
  VACANCY_LIST,
} from './layout-pages.helpers';

const goBackMap = {
  [`${SELECT_WORKSPACE}-${ME}`]: { route: SELECT_WORKSPACE, title: 'Back to select workspace' },
  [STORE_SUMMARY]: { route: PUBLICATION_STORE, title: 'NAVIGATION.PUBLICATION_STORE' },
  [STORE_ORDER_COMPLETE]: { route: DASHBOARD, title: 'DASHBOARD_PAGE.TITLE' },
  [SUMMARY_ORDER_COMPLETE]: {
    route: VACANCY_LIST,
    title: 'PUBLISHING_OPTIONS.BUTTON.BACK_TO_JOB_ADS',
  },
  [STORE_COMPARISON_TABLE]: {
    route: PUBLICATION_STORE,
    title: 'PUBLICATION_STORE.COMPARISON_TABLE.GO_BACK',
  },
  [ME]: { route: DASHBOARD, title: 'DASHBOARD_PAGE.TITLE' },
  [ANY_ORDER_COMPLETE]: { route: DASHBOARD, title: 'DASHBOARD_PAGE.TITLE' },
  [PUBLISH_VACANCY]: { route: VACANCY_LIST, title: 'PUBLISHING_OPTIONS.BUTTON.BACK_TO_JOB_ADS' },
  [EDIT_VACANCY]: { route: VACANCY_LIST, title: 'PUBLISHING_OPTIONS.BUTTON.BACK_TO_JOB_ADS' },
  [CREATE_VACANCY]: { route: VACANCY_LIST, title: 'PUBLISHING_OPTIONS.BUTTON.BACK_TO_JOB_ADS' },
  [BOOST_VACANCY]: {
    route: PREVIEW_VACANCY_WITH_ID,
    title: 'PUBLISHING_OPTIONS.BUTTON.BACK_TO_JOB_AD',
  },
  [CLAIM_MANAGEMENT]: { route: CLAIM_MANAGEMENT, title: 'CLAIM_MANAGEMENT.GO_BACK' },
};

const createRouteHash = (prev: string | undefined, curr: string | undefined) =>
  shouldCheckPrevPage(curr) ? `${prev ? prev + '-' : ''}${curr ?? ''}` : curr ?? '';

const mapUrlToPageName = (url: string) => {
  switch (true) {
    case url.includes(MY_USER_PROFILE):
      return ME;
    case url.includes(SELECT_WORKSPACE):
      return SELECT_WORKSPACE;
    case url.includes(EDIT_VACANCY):
      return EDIT_VACANCY;
    case url.includes(CREATE_VACANCY):
      return CREATE_VACANCY;
    case url.includes(PUBLISH_VACANCY):
      return PUBLISH_VACANCY;
    case url.includes(STORE_COMPARISON_TABLE):
      return STORE_COMPARISON_TABLE;
    case url.includes(STORE_ORDER_COMPLETE):
      return STORE_ORDER_COMPLETE;
    case url.includes(SUMMARY_ORDER_COMPLETE):
      return SUMMARY_ORDER_COMPLETE;
    case url.includes(ANY_ORDER_COMPLETE):
      return ANY_ORDER_COMPLETE;
    case url.includes(STORE_SUMMARY):
      return STORE_SUMMARY;
    case url.includes(BOOST_VACANCY):
      return BOOST_VACANCY;
    case url.includes(CLAIM_MANAGEMENT):
      return CLAIM_MANAGEMENT;
    default:
      return undefined;
  }
};

const shouldCheckPrevPage = (curr: string | undefined) =>
  !![ME].find((route) => curr?.includes(route));

export const getRouteWithParams = (
  map: { route: string; title: string },
  paramMap: Params
): { route: string; title: string } | undefined => {
  if (!map?.route.includes(':')) return map;

  const slugMatches = map.route.match(/:\w+/g) || [];
  slugMatches.forEach((slug) => {
    const slugKey = slug.slice(1);
    const slugValue = paramMap[slugKey];
    if (slugValue !== undefined) {
      map.route = map.route.replace(slug, slugValue);
    }
  });

  return map;
};

export const getGoBackValue = (
  prevUrl: string,
  currentUrl: string,
  params: Params
): { route: string; title: string } | undefined => {
  const routeHash = createRouteHash(mapUrlToPageName(prevUrl), mapUrlToPageName(currentUrl));
  const map = goBackMap[routeHash];
  return getRouteWithParams(map, params);
};
