import { AppConfig } from '@config/app.config';

const { routes } = AppConfig;

export const ANY_ORDER_COMPLETE = AppConfig.routes.orderComplete;
export const ANY_ORDER_SUMMARY = AppConfig.routes.orderSummary;
export const BOOST_VACANCY = `${routes.credits}/${routes.productsOptions}`;
export const CLAIM_COMPANY = AppConfig.routes.claimCompany;
export const CLAIM_MANAGEMENT = routes.claimManagement;
export const CLAIM_MANAGEMENT_DETAILS_WITH_ID = `${CLAIM_MANAGEMENT}/`; // with ID appended
export const CMS_PAGE = AppConfig.routes.cms;
export const CREATE_SUMMARY = `${AppConfig.routes.create}/${AppConfig.routes.summary}`;
export const CREATE_VACANCY = AppConfig.routes.create;
export const DASHBOARD = AppConfig.routes.dashboard;
export const EDIT_VACANCY = `${AppConfig.routes.vacancy}/${AppConfig.routes.edit}`;
export const LANDING_PAGE = AppConfig.routes.home;
export const LOGIN_PAGE = AppConfig.routes.login;
export const ME = AppConfig.routes.me;
export const MY_USER_PROFILE = `${AppConfig.routes.user}/${AppConfig.routes.me}`;
export const ONBOARDING = AppConfig.routes.onboarding;
export const PREVIEW_VACANCY = `${AppConfig.routes.vacancy}/${AppConfig.routes.preview}`;
export const PUBLICATION_STORE = AppConfig.routes.publicationStore;
export const PUBLISH_VACANCY = `${AppConfig.routes.vacancy}/${AppConfig.routes.publish}/`;
export const SELECT_WORKSPACE = AppConfig.routes.selectAccountMembership;
export const STORE_COMPARISON_TABLE = AppConfig.routes.comparisonTable;
export const STORE_SUMMARY = `${AppConfig.routes.publicationStore}/${AppConfig.routes.orderSummary}`;
export const STORE_ORDER_COMPLETE = `${STORE_SUMMARY}/${AppConfig.routes.orderComplete}`;
export const SUMMARY_ORDER_COMPLETE = `${AppConfig.routes.summary}/${AppConfig.routes.orderComplete}`;
export const SUPPORT_CENTER = AppConfig.routes.supportCenter;
export const VACANCY_ATS = `${AppConfig.routes.applications}`;
export const VACANCY_LIST = AppConfig.routes.vacancy;
export const VERIFY_IDENTITY = AppConfig.routes.verifyIdentity;

export const PREVIEW_VACANCY_WITH_ID = `${routes.vacancy}/${routes.publish}/${routes.preview}/:id`;

// we could replace this with regex
export const urlMatchesAnyPage = (pages: string[], url: string) =>
  pages.some((page) => url.includes(page));
