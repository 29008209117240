import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppConfig } from '@app/config/app.config';
import { selectApplicationStatuses } from '@mkp/application/state';
import {
  applicationStatusApiActions,
  applicationStatusesGuardActions,
} from '@mkp/application/state/actions';
import { SnackbarService } from '@mkp/shared/ui-library';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, filter, map, race, take } from 'rxjs';

export const applicationStatusesExistGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  const snackbarService = inject(SnackbarService);

  const showSnackbarAndRedirect = () => {
    snackbarService.showError('CANDIDATE_PAGE.COMMENT.ERROR');
    return router.parseUrl(AppConfig.routes.vacancy);
  };

  store.dispatch(applicationStatusesGuardActions.canActivate());

  return hasStatuses(store).pipe(
    map((status) => status || showSnackbarAndRedirect()),
    take(1)
  );
};

const hasStatusesApi = (): Observable<boolean> => {
  const actions$ = inject(Actions);
  return race(
    actions$.pipe(
      ofType(applicationStatusApiActions.applicationStatusesLoadedSuccess),
      map(() => true)
    ),
    actions$.pipe(
      ofType(applicationStatusApiActions.applicationStatusesLoadedFailure),
      map(() => false)
    )
  );
};

const hasStatusesInStore = (store: Store): Observable<boolean> => {
  return store.select(selectApplicationStatuses).pipe(
    filter((statuses) => !!statuses?.length),
    map(() => true)
  );
};

const hasStatuses = (store: Store): Observable<boolean> => {
  return race(hasStatusesInStore(store), hasStatusesApi());
};
