<button
  mat-flat-button
  class="ui-button"
  [class]="classList"
  [type]="type"
  [disabled]="disabled"
  [disableRipple]="config?.disableRipple"
  (focus)="handleFocus.emit($event)"
>
  <ng-content />
</button>
