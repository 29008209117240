import { createActionGroup, props } from '@ngrx/store';
import { LegalEntity } from '@mkp/legal-entity/data-access';
import { Account } from '@mkp/account/data-access';
import { CompanyDto } from '@mkp/company/data-access';
import { AccountMembership } from '@mkp/account-membership/data-access';

export const legalEntityDetailsActions = createActionGroup({
  source: 'Legal Entity Details',
  events: {
    'Page Entered': props<{ legalEntityId: string }>(),
    'Legal Entity Set Selected': props<{ legalEntity: LegalEntity }>(),
    'Legal Entity Load Success': props<{ legalEntity: LegalEntity }>(),
    'Legal Entity Load Failure': props<{ error: { message: string } }>(),
    'Accounts Load Success': props<{ accounts: Account[] }>(),
    'Accounts Load Failure': props<{ error: { message: string } }>(),
    'Companies Load Success': props<{ companies: CompanyDto[] }>(),
    'Companies Load Failure': props<{ error: { message: string} }>(),
    'Account Memberships Load Success': props<{ accountMemberships: AccountMembership[], totalCount: number }>(),
    'Account Memberships Load Failure': props<{ error: { message: string} }>(),
  },
});