import { filter, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectSelectedAccount } from '@mkp/account/state';
import { selectIsBoUser } from '@user/store/selectors/user.selectors';
import { map } from 'rxjs/operators';
import { Router, UrlTree } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { selectIsNewAccountManagement } from '@mkp/debug/state';
import { concatLatestFrom } from '@ngrx/effects';

export const hasSelectedWorkspaceGuard: () => Observable<boolean | UrlTree> = () => {
  const store = inject(Store);
  const router = inject(Router);
  return store.select(selectIsBoUser).pipe(
    filter((isBoUser) => isBoUser !== null),
    concatLatestFrom(() => [
      store.select(selectSelectedAccount),
      store.select(selectIsNewAccountManagement),
    ]),
    map(([isBoUser, selectedAccount, isEnabled]) => isEnabled && !isBoUser && !selectedAccount),
    map((shouldRedirect) =>
      shouldRedirect ? router.createUrlTree([AppConfig.routes.selectAccountMembership]) : true
    )
  );
};
