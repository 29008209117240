export enum ATSPlatform {
  Alpha = 'ALPHA',
  BankingJobs = 'BANKINGJOBS',
  FinanceJobs = 'FINANCEJOBS',
  IctCareer = 'ICTCAREER',
  IngJobs = 'INGJOBS',
  Jobs = 'JOBS',
  Jobs4Sales = 'JOBS4_SALES',
  JobScout24 = 'JOBSCOUT24',
  JobUp = 'JOBUP',
  JobWinner = 'JOBWINNER',
  JSM = 'JSM',
  Medtalents = 'MEDTALENTS',
  TopJobs = 'TOPJOBS',
}

const ATSPlatformMap = Object.values(ATSPlatform).reduce(
  (acc, value) => ({ ...acc, [value]: value }),
  {} as Record<ATSPlatform, ATSPlatform>
);

export const getATSPlatform = (platformString: string): ATSPlatform | null => {
  const platform = ATSPlatformMap[platformString as ATSPlatform];

  if (platform) {
    return platform;
  }

  console.error(`ATSPlatform ${platformString} was not recognized`);
  return null;
};
