export enum ApplicationStep {
  New = 1,
  Suitable,
  InConversation,
  Hired,
  Unsuitable,
  Declined,
}

export type ApplicationStepWithoutDeclined = Exclude<ApplicationStep, ApplicationStep.Declined>;

export const isApplicationStep = (value: number): value is ApplicationStep => {
  return Object.values(ApplicationStep).filter(Number).includes(value);
};
