import {
  Application,
  ApplicationStatus,
  ApplicationStep,
  ApplicationStepWithoutDeclined,
  getATSPlatform,
} from '@mkp/application/models';
import { ApplicationDto } from './application.dto';

export const mapApplicationDtoToModel = (dto: ApplicationDto): Application => ({
  ...dto,
  platform: getATSPlatform(dto.platform),
  createdAt: new Date(dto.createdAt),
  updatedAt: dto.updatedAt ? new Date(dto.updatedAt) : null,
});

export const applicationByStatusesMap = (
  statuses: ApplicationStatus[]
): Record<ApplicationStepWithoutDeclined, { statusIds: string[] }> => {
  const stepToStatusMap = statuses.reduce(
    (acc: Record<ApplicationStep, string>, status: ApplicationStatus) => ({
      ...acc,
      [status.step]: status.id,
    }),
    {} as Record<ApplicationStep, string>
  );

  return {
    [ApplicationStep.New]: {
      statusIds: [stepToStatusMap[ApplicationStep.New]],
    },
    [ApplicationStep.Suitable]: { statusIds: [stepToStatusMap[ApplicationStep.Suitable]] },
    [ApplicationStep.InConversation]: {
      statusIds: [stepToStatusMap[ApplicationStep.InConversation]],
    },
    [ApplicationStep.Hired]: { statusIds: [stepToStatusMap[ApplicationStep.Hired]] },
    [ApplicationStep.Unsuitable]: {
      statusIds: [
        stepToStatusMap[ApplicationStep.Unsuitable],
        stepToStatusMap[ApplicationStep.Declined],
      ],
    },
  };
};
