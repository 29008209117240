import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { AssetComponent } from '@mkp/shared/ui-asset';
import { BUTTON_DEFAULT_CONFIG } from './button.config';
import * as ButtonHelpers from './button.helpers';
import { ActionState, Button, ButtonColor, ButtonType } from './button.model';

@Component({
  selector: 'ui-button',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.colors.scss', './button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  classList = '';

  /**
   * @Note
   *
   * I'm not sure what we want to do with this cause
   * I couldn't see any changes with the old button
   * but a cool implementation of isMobile$ would be:
   *
   * .html
   * <button
   * [ngClass]="{
   *  'body-default bold': bodyDefault$ | async,
   *  'body-s semibold': (bodyDefault$ | async) === false
   * }"
   *
   * .ts
   * bodyDefault$ = this.bos.isMobile$.pipe(map((mobile) => ...));
   */

  @Input() config!: Button;
  @Input() state!: ActionState;
  @Input() type: ButtonType = 'button';

  @HostBinding('attr.disabled') @Input() disabled!: boolean;

  @Input() @HostBinding('class.btn-spinner') loading = false;
  @HostBinding('class.button-full-width-mobile') get fullWidthMobile(): boolean {
    return !!this.config?.mobileFullWidth;
  }
  @HostBinding('class.button-full-width') get fullWidth(): boolean {
    return !!this.config?.fullWidth;
  }
  @HostBinding('class.button-color-link') get isLink(): boolean {
    return this.config?.color === ButtonColor.Link;
  }
  @HostBinding('class.button-floatable') get isFloatable(): boolean {
    return !!this.config?.floatable;
  }
  @HostBinding('class.btn-icon-spinner') get isButtonWithIcon(): boolean {
    return !!this.uiAssetComponent && this.loading;
  }

  /** @deprecated */
  @Input() disableRipple = false;

  @Output() handleFocus = new EventEmitter<FocusEvent>();

  @ContentChild(AssetComponent) uiAssetComponent: AssetComponent | undefined;
  private default: Button = BUTTON_DEFAULT_CONFIG;

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.clickEventListener();
  }

  ngAfterContentInit() {
    if (this.uiAssetComponent && this.uiAssetComponent.elementRef) {
      const element = this.uiAssetComponent.elementRef.nativeElement;
      const tagName = element.tagName.toLowerCase();
      const iconPosition = element.parentElement?.innerHTML.startsWith(`<${tagName}`)
        ? 'left'
        : 'right';
      this.classList += ` button-icon-${iconPosition}`;
    }
  }

  ngOnChanges(): void {
    this.config = { ...this.default, ...this.config };
    this.classList = ButtonHelpers.styling(this.config, this.state);
  }

  clickEventListener(): void {
    this.ngZone.runOutsideAngular(() => {
      this.elementRef.nativeElement.addEventListener(
        'click',
        this.haltDisabledEvents, // Note: button or anchor might be bound to an element that already has `click` listeners
        // registered. For instance, the `routerLink` will execute its listener first and start
        // the navigation even if button or anchor is disabled. We want to capture the event before other
        // listeners and stop the event propagation if button or anchor is disabled.
        true
      );
    });
  }

  haltDisabledEvents = (event: Event): void => {
    // A disabled button shouldn't apply any actions
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  };

  ngOnDestroy(): void {
    this.elementRef.nativeElement.removeEventListener('click', this.haltDisabledEvents, true);
  }
}
