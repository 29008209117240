import { createSelector } from '@ngrx/store';
import { applicationFeature } from './application.reducer';

export const {
  selectEntities,
  selectAll: selectApplications,
  selectFetchState: selectApplicationsFetchState,
  selectApplicationStatuses,
} = applicationFeature;

export const selectApplicationById = (applicationId: string | undefined) =>
  createSelector(applicationFeature.selectEntities, (applications) =>
    applicationId ? applications[applicationId] : undefined
  );
