import { CompanyProfile } from '@company/models';
import { UUID } from '@mkp/shared/data-access';
import { createAction, props } from '@ngrx/store';

export const updateCompanySuccess = createAction(
  '[Company API] Update Company Success',
  props<{ company: CompanyProfile }>()
);
export const updateCompanyFailure = createAction(
  '[Company API] Update Company Failure',
  props<{ companyId: UUID; error: { message: string } }>()
);

export const createCompanyLocationSuccess = createAction(
  '[Company API] Create Company Location Success',
  props<{ companyId: UUID }>()
);
export const createCompanyLocationFailure = createAction(
  '[Company API] Create Company Location Failure',
  props<{ companyId: UUID; error: { message: string } }>()
);

export const updateCompanyLocationSuccess = createAction(
  '[Company API] Update Company Location Success',
  props<{ companyId: UUID }>()
);
export const updateCompanyLocationFailure = createAction(
  '[Company API] Update Company Location Failure',
  props<{ companyId: UUID; error: { message: string } }>()
);

export const loadCompanySuccess = createAction(
  '[Company API] Load Company Success',
  props<{ company: CompanyProfile }>()
);
export const loadCompanyFailure = createAction(
  '[Company API] Load Company Failure',
  props<{ companyId: UUID; error: { message: string } }>()
);

export const loadCompanyIfNotExistSuccess = createAction(
  '[Company API] Load Company If Does Not Exist Success',
  props<{ company: CompanyProfile }>()
);
export const loadCompanyIfNotExistFailure = createAction(
  '[Company API] Load Company If Does Not Exist Failure',
  props<{ companyId: UUID; error: { message: string } }>()
);

export const loadCompaniesFromVacancySuccess = createAction(
  '[Company API] Load Companies From Vacancy Success',
  props<{ vacancyId: string; companyProfiles: CompanyProfile[] }>()
);
export const loadCompaniesFromVacancyFailure = createAction(
  '[Company API] Load Companies From Vacancy Failure',
  props<{ vacancyId: string; error: { message: string } }>()
);

export const loadCompaniesFromAccountSuccess = createAction(
  '[Company API] Load Companies From Account Success',
  props<{ accountId: string; companyProfiles: CompanyProfile[] }>()
);
export const loadCompaniesFromAccountFailure = createAction(
  '[Company API] Load Companies From Account Failure',
  props<{ accountId: string; error: { message: string } }>()
);

export const deleteLocationSuccess = createAction(
  '[Company API] Delete Company Profile Location Success',
  props<{ id: string; companyProfileId: string }>()
);
export const deleteLocationFailure = createAction(
  '[Company API] Delete Company Profile Location Failure',
  props<{ error: { message: string } }>()
);
