import { InjectionToken } from '@angular/core';
import { environment } from '@env/environment';

export const APP_CONFIG = new InjectionToken('app.config');

export const AppConfig = {
  routes: {
    home: 'home',
    callback: 'callback',
    error401: 'unauthorized',
    error403: 'forbidden',
    error404: 'not-found',
    error500: 'internal-error',
    styleGuide: 'style-guide',
    user: 'user',
    users: 'user/list',
    account: 'user/me',
    applications: 'applications',
    dashboard: 'dashboard',
    vacancy: 'vacancy',
    company: 'company-profiles',
    claimManagement: 'claim-management',
    onboarding: 'onboarding',
    onboardingStep: 'onboardingStep',
    step2: '2',
    step3: '3',
    logout: 'logout',
    login: 'login',
    resetPassword: 'reset-password',
    upsell: 'upgrade',
    summary: 'summary',
    productsOptions: 'product-options',
    productsHistory: 'product-history',
    promotion: 'promotion',
    credits: 'credits',
    list: 'list',
    id: ':id',
    companyProfileId: ':id/edit',
    idDuplicate: ':id/duplicate',
    success: 'success',
    orderComplete: 'order-complete',
    redemptionError: 'redemption-error',
    me: 'me',
    new: 'new',
    idSettings: ':id/settings',
    idEdit: ':id/edit',
    about: 'about',
    edit: 'edit',
    duplicate: 'duplicate',
    idPreview: ':id/preview',
    preview: 'preview',
    invoices: 'invoices',
    invoiceDetails: 'details/:id',
    cms: 'cms',
    supportCenter: 'support-center',
    productOffering: 'product-offering',
    unsubscribe: 'unsubscribe',
    verifyIdentity: 'verify-identity',
    claimCompany: 'claim-company',
    create: 'create',
    publish: 'publish',
    textEditor: 'text-editor',
    publicationStore: 'store',
    orderSummary: 'order-summary',
    comparisonTable: 'comparison-table',
    selectAccountMembership: 'select-workspace',
  },
  sentry: {
    dsn: 'https://4ce8b65677ff4839814b92ef12425be5@sentry.io/1445521',
  },
  endpoints: {
    apiUrl: 'localhost:8085',
    mediaApiUrl: environment.api.media,
  },
  vacancyTypeaheadMaxLength: 100,
  creditRedemptionsGap: 2,
  pagination: {
    pageSize: 20,
  },
  defaultProfilePicture: '/assets/images/uploadplaceholder.png',
  pictureSize: 'x200x200',
  fakeId: '87623guidvsjghr48798few',
  uploadPlaceHolder: '/assets/images/upload.png',
  hashingSecret: 'HeyPascalCanYouReverseThisPassword',
  shopTaxon: 'JS24',
  minimumPictureSize: 320,
  onboardingCfg: {
    startingStep: 1,
    numberOfSteps: 5,
  },
};

export const LocalStorageKeys = {
  redirectUri: 'redirectUri',
  onboardingUser: 'onboardingUser',
  logoGuidance: 'logoGuidance',
};
